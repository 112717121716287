// CardAttribute.js
import React from "react";
import { Card } from "react-bootstrap";

export default function CardAttribute({
  language,
  setShowChildComponent,
  changeColorBody,
  attribute,
}) {
  return (
    <div className="card-Attributies">
      <Card
        onClick={() => setShowChildComponent(attribute)}
        style={{ margin: "0px" }}
      >
        <Card.Body className={changeColorBody()}>
          <Card.Title>
            <span
              style={
                attribute.is_required === false
                  ? { borderBottom: "2px dashed #DBA42A" }
                  : { borderBottom: "2px dashed #E53838" }
              }
              className="fs-3"
            >
              {attribute.name}
              {attribute.type}
            </span>
          </Card.Title>
          <Card.Text>
            {language ? attribute.en_description : attribute.ar_description}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
