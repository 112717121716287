import React from "react";
import "./head.css";

const Header = ({ darkMode, setDarkMode, language, setlanguage }) => {
  const Switchlanguage = () => {
    setlanguage(!language);
  };

  const handleClick = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className="d-flex">
      <div onClick={Switchlanguage} className="tooglee">
        <div className="d-flex">
          <span
            className="  mt-1 mx-1 ss"
            style={
              language === false ? { color: "white" } : { color: "#003b57" }
            }
          >
            AR
          </span>

          <span
            className="  mt-1 mx-3 ss"
            style={
              language === true ? { color: "white" } : { color: "#003b57" }
            }
          >
            EN
          </span>
        </div>
        {language ? (
          <div className="toogle-rightt" style={{ position: "absolute" }}></div>
        ) : (
          <div className="toogle-leftt" style={{ position: "absolute" }}></div>
        )}
      </div>

      <div onClick={handleClick} className="toogle">
        <div className="d-flex">
          <span
            className="fa-regular fa-sun fs-5 mt-2 mx-1 "
            style={darkMode === false ? { color: "white" } : { color: "white" }}
          ></span>

          <span
            className="fa-regular fa-moon fs-5 mt-2 mx-3"
            style={darkMode === true ? { color: "white" } : { color: "white" }}
          ></span>
        </div>
        {darkMode ? (
          <div className="toogle-right" style={{ position: "absolute" }}></div>
        ) : (
          <div className="toogle-left" style={{ position: "absolute" }}></div>
        )}
      </div>
    </div>
  );
};

export default Header;
