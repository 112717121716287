import React from "react";
import "./sidebar.css";
import logo from "./fnallogo.png";
import { Accordion } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function Sidebars({
  darkMode,
  language,
  contents,
  setActiveData,
}) {
  const features = useSelector((state) => state.app.features);

  const { projectId } = useParams();

  const changeColorFont = () => {
    if (darkMode === false) {
      return { color: "#011B35" };
    } else {
      return { color: "white" };
    }
  };

  const [openAccordion, setOpenAccordion] = useState([]);

  const toggleAccordion = (index) => {
    if (openAccordion.includes(index)) {
      setOpenAccordion(openAccordion.filter((item) => item !== index));
    } else {
      setOpenAccordion([...openAccordion, index]);
    }
  };

  // تحديد الرابط النشط داخل كل قائمة منفصلة
  const [activeLinks, setActiveLinks] = useState(
    Array(features.length).fill(null)
  );
  const handleNavLinkClick = (featureIndex, subFeatureIndex) => {
    setActiveData({
      featureIndex,
      subFeatureIndex,
    });
  };

  return (
    <div>
      <div className="main-contaner d-flex">
        <div
          className={darkMode ? "side_nav" : "side_nav_1"}
          style={
            darkMode === false && language === false
              ? {
                  boxShadow:
                    "8px 0px 0px 10px #d2dfe6, -10px 5px 9px -5px #000",
                }
              : darkMode === true && language === false
              ? {
                  boxShadow:
                    "8px 0px 0px 10px #011b35, -10px 5px 9px -5px #000",
                }
              : {}
          }
        >
          <div className="my-3">
            <div>
              <img className="logo" alt="ss" src={logo}></img>
              <h1 className="Overview-text">{contents.Overview}Overview</h1>
            </div>
            {features.map((feature, featureIndex) => (
              <div key={featureIndex}>
                <Accordion className="d-flex flex-column ">
                  <Accordion>
                    <Accordion.Header
                      onClick={() => {
                        toggleAccordion(featureIndex);
                      }}
                    >
                      <i
                        className={`fa-solid fa-caret-right mx-3 ${
                          openAccordion.includes(featureIndex)
                            ? "rotatedis"
                            : "rotate"
                        }`}
                        style={changeColorFont()}
                      ></i>
                      <span
                        className="accordion-header overflow-hidden"
                        style={changeColorFont()}
                      >
                        {language ? feature.en_name : feature.ar_name}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <NavLink
                        to={`/Ourprojects/Invesment/${projectId}/Overviwe/${feature.id}`}
                        className={`link ${
                          activeLinks[featureIndex] === null ? "active" : ""
                        }`}
                        style={changeColorFont()}
                        onClick={() => {
                          handleNavLinkClick(featureIndex);
                        }}
                      >
                        {contents.Overview}Overview
                      </NavLink>
                    </Accordion.Body>

                    {feature.sub_features.map((subFeature, subFeatureIndex) => (
                      <div key={subFeatureIndex}>
                        <Accordion.Body>
                          <NavLink
                            to={`/Ourprojects/Invesment/${projectId}/Contract/${feature.id}/${subFeature.id}`}
                            className={`link ${
                              subFeatureIndex === activeLinks[featureIndex]
                                ? "active"
                                : ""
                            }`}
                            style={changeColorFont()}
                            onClick={() => {
                              handleNavLinkClick(featureIndex, subFeatureIndex);
                            }}
                          >
                            {language ? subFeature.en_name : subFeature.ar_name}
                          </NavLink>
                        </Accordion.Body>
                      </div>
                    ))}
                  </Accordion>
                </Accordion>
              </div>
            ))}
          </div>
        </div>
        <div className="content"></div>
      </div>
    </div>
  );
}
