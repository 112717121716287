import React from "react";

export default function DeleteBtn({
  setShowChildComponent,
  setSelectedProjectId,
  showChildComponent,
  projects,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="71"
      height="67"
      viewBox="0 0 71 67"
      className="svg-img1"
      onClick={() => {
        setShowChildComponent(!showChildComponent);
        setSelectedProjectId(projects.id);
      }}
    >
      <g id="Group_149" data-name="Group 149" transform="translate(-1604 -553)">
        <rect
          id="Rectangle_287"
          data-name="Rectangle 287"
          width="71"
          height="67"
          rx="23"
          transform="translate(1604 553)"
          fill="#003b57"
        />
        <g
          id="vuesax_linear_trush-square"
          data-name="vuesax/linear/trush-square"
          transform="translate(1612.838 561.089)"
        >
          <g id="trush-square" transform="translate(0 0)">
            <path
              id="Vector"
              d="M14.938,42.681h12.8c10.67,0,14.938-4.268,14.938-14.938v-12.8C42.681,4.268,38.413,0,27.743,0h-12.8C4.268,0,0,4.268,0,14.938v12.8C0,38.413,4.268,42.681,14.938,42.681Z"
              transform="translate(4.268 4.268)"
              fill="none"
            />
            <g id="Group" transform="translate(13.018 13.743)">
              <path
                id="Vector-2"
                data-name="Vector"
                d="M25.182.7A139.869,139.869,0,0,0,11.161,0a82.856,82.856,0,0,0-8.3.427L0,.7"
                transform="translate(0 4.866)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <path
                id="Vector-3"
                data-name="Vector"
                d="M0,4.161.3,2.326C.512,1,.683,0,3.052,0H6.722c2.369,0,2.54,1.046,2.753,2.326l.3,1.814"
                transform="translate(7.704)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <path
                id="Vector-4"
                data-name="Vector"
                d="M19.185,0l-.918,14.085c-.149,2.2-.277,3.905-4.183,3.905H5.1c-3.905,0-4.033-1.707-4.183-3.905L0,0"
                transform="translate(2.988 5.741)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
            </g>
            <path
              id="Vector-5"
              data-name="Vector"
              d="M0,0H51.217V51.217H0Z"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
