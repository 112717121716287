import React, { useRef } from "react";

export default function Api({ language, apiValue }) {
  const inputRef = useRef(null);

  const handleCopyClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
    }
  };

  return (
    <div>
      <h1 className="text-title ">Api</h1>

      <div className="res w-100 d-flex flex-wrap">
        <h1 className="my-5 fs-5 mx-3 w-75">
          {apiValue.api ? apiValue.api.endpoint : ""}
        </h1>

        <div className={language ? "type-class" : " type-class-arabic"}>
          <span className={language ? "type-header-arabic" : "type-header"}>
            {apiValue.api ? apiValue.api.type : ""}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="108.22"
            height="64.618"
            viewBox="0 0 108.22 64.618"
          >
            <g id="Group_66" data-name="Group 66" transform="translate(0 0)">
              <g
                id="Rectangle_24"
                data-name="Rectangle 24"
                transform="translate(5.153 0)"
                fill="#003b57"
                stroke="#003b57"
                strokeWidth="1"
              >
                <rect width="103.066" height="42.372" stroke="none" />
                <rect
                  x="0.5"
                  y="0.5"
                  width="102.066"
                  height="41.372"
                  fill="none"
                />
              </g>
              <path
                id="Path_1"
                data-name="Path 1"
                d="M9137,1693v21.09l11.034-10.371,11.536-10.193Z"
                transform="translate(-9052.829 -1650.628)"
                fill="#003b57"
                stroke="#003b57"
                strokeWidth="1"
                opacity="0.89"
              />
              <g
                id="Path_3"
                data-name="Path 3"
                transform="translate(0 0)"
                fill="#003b57"
              >
                <path
                  d="M 5.725915908813477 41.87178039550781 C 2.844335794448853 41.87178039550781 0.4999959170818329 39.52743911743164 0.4999959170818329 36.64585876464844 L 0.4999959170818329 5.7259202003479 C 0.4999959170818329 2.844340085983276 2.844335794448853 0.5000001788139343 5.725915908813477 0.5000001788139343 C 8.60749626159668 0.5000001788139343 10.95183563232422 2.844340085983276 10.95183563232422 5.7259202003479 L 10.95183563232422 36.64585876464844 C 10.95183563232422 39.52743911743164 8.60749626159668 41.87178039550781 5.725915908813477 41.87178039550781 Z"
                  stroke="none"
                />
                <path
                  d="M 5.725915908813477 1 C 3.120036125183105 1 0.9999961853027344 3.120029449462891 0.9999961853027344 5.725910186767578 L 0.9999961853027344 36.64585876464844 C 0.9999961853027344 39.25173950195312 3.120036125183105 41.37178039550781 5.725915908813477 41.37178039550781 C 8.331795692443848 41.37178039550781 10.45183563232422 39.25173950195312 10.45183563232422 36.64585876464844 L 10.45183563232422 5.725910186767578 C 10.45183563232422 3.120029449462891 8.331795692443848 1 5.725915908813477 1 M 5.725915908813477 0 C 8.888256072998047 0 11.45183563232422 2.563579559326172 11.45183563232422 5.725910186767578 L 11.45183563232422 36.64585876464844 C 11.45183563232422 39.80820083618164 8.888256072998047 42.37178039550781 5.725915908813477 42.37178039550781 C 2.563575744628906 42.37178039550781 -3.814697265625e-06 39.80820083618164 -3.814697265625e-06 36.64585876464844 L -3.814697265625e-06 5.725910186767578 C -3.814697265625e-06 2.563579559326172 2.563575744628906 0 5.725915908813477 0 Z"
                  stroke="none"
                  fill="#003b57"
                />
              </g>
            </g>
          </svg>
        </div>
        <div className="div-input w-100">
          <input
            type="text"
            placeholder="{BaseURL}\Endpoint"
            className="my-4 mx-3 p-2 input-radios"
            readOnly
            ref={inputRef}
            value={apiValue.api ? apiValue.api.endpoint : ""}
          />
          <div className="copy-icon">
            <button
              className="fa-solid fa-copy copyy"
              onClick={handleCopyClick}
            ></button>
          </div>
        </div>

        <span className="mx-3 fs-2 ">payload</span>

        {apiValue.api ? (
          apiValue.api.payloads.map((item, index) => (
            <div className=" my-3 mx-2 payload w-100 flex-wrap" key={index}>
              <span
                className="   w-25 d-flex justify-content-center align-items-center ratee"
                style={
                  item.is_required === false
                    ? { textDecoration: "underline dotted  #DBA42A" }
                    : { textDecoration: "underline  dotted  #E53838" }
                }
              >
                {item.name}
              </span>
              <div className="d-flex w-75 flex-wrap payloadss  fs-2">
                {item.payload_value &&
                  item.payload_value.split("-").map((value, i) => (
                    <span key={i} className="rate mx-2 my-1">
                      {value.trim()}
                    </span>
                  ))}
              </div>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
