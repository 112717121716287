import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../Styles/Addproject.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { changeBackgroundInput } from "../styleHelpers/changeBackgroundInput.js";

export default function Addproject({ darkMode, loading, setLoading }) {
  const [selectedImage, setSelectedImage] = useState();
  const [imageSelected, setImageSelected] = useState();

  var [addProject, setAddProject] = useState({
    id: 1,
    ar_name: "",
    en_name: "",
    ar_description: "",
    en_description: "",
    image_url: "",
    image_id: 1,
  });

  const handleAddProjectChange = (e) => {
    const { name, value } = e.target;
    setAddProject((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const { name, value } = event.target;
    setAddProject((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setSelectedImage(event.target.files[0]);
    setImageSelected(URL.createObjectURL(event.target.files[0]));
  };

  const notify = (message, type) => toast.error(message);

  const handleFileUpload = async () => {
    if (!imageSelected) {
      notify("Please select an image");
      return;
    } else {
      setLoading(true);
    }
    var config = {
      method: "post",
      url: "https://etifaq.app/api/projects",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        Authorization: "Bearer 1734|WZFIBiz63fnnhWryRxse1iE7fGKlG4kcjGOtUrzg",
        "X-CSRF-TOKEN": "",
      },
      data: addProject,
    };

    const fromData = new FormData();
    fromData.append("file", selectedImage);
    const config_file = {
      method: "post",
      url: "https://etifaq.app/api/files",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "multipart/from-data",
        "content-length": `${selectedImage.size}`,
        Authorization: "Bearer 1734|WZFIBiz63fnnhWryRxse1iE7fGKlG4kcjGOtUrzg",
      },
      data: fromData,
    };

    await axios
      .request(config_file)
      .then(async (img_response) => {
        addProject.image_id = img_response.data.data.id;
        await axios
          .request(config)
          .then((response) => {
            let id = response.data.data.id;
            window.location.pathname = `/Ourprojects/Addproject/Addfeature/newfeature/${id}`;
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  return (
    <Container className="d-flex flex-column ">
      {loading && <Loader />}
      {!loading && (
        <div>
          <Row
            xs={12}
            md={12}
            className="d-flex flex-column justify-content-center align-items-center my-3"
          >
            <p className="our-project-text">Add project</p>
          </Row>

          <Form className="d-flex flex-wrap justify-content-between   width-form">
            <Col lg={3} md={12} xs={12}>
              <div className="new-project">
                <div className="link-card">
                  <div>
                    <label htmlFor="image-upload" className="upload-btn">
                      {imageSelected ? (
                        <img
                          src={imageSelected}
                          alt="preview"
                          className="preview-image"
                          style={{ width: "90%", maxHeight: "300px" }}
                        />
                      ) : (
                        <span className="fs-3">+ Add logo image</span>
                      )}
                    </label>
                    <input
                      type="file"
                      id="image-upload"
                      accept="image/*"
                      name="image_url"
                      value={''}
                      // value={addProject.image_url}
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={6} md={12} xs={12} className="">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="fs-3">Project name</Form.Label>
                <Form.Control
                  className="mt-2 p-3"
                  type="text"
                  placeholder="Arabic name"
                  style={changeBackgroundInput(darkMode)}
                  name="ar_name"
                  value={addProject.ar_name}
                  onChange={handleAddProjectChange}
                />
                <Form.Control
                  className="mt-2 p-3"
                  type="text"
                  placeholder="English name"
                  style={changeBackgroundInput(darkMode)}
                  name={"en_name"}
                  value={addProject.en_name}
                  onChange={handleAddProjectChange}
                />

                <Form.Label className="my-2 fs-3">Description</Form.Label>
                <Form.Control
                  className="mt-2 p-3"
                  type="text"
                  placeholder="Arabic description"
                  style={changeBackgroundInput(darkMode)}
                  name={"ar_description"}
                  value={addProject.ar_description}
                  onChange={handleAddProjectChange}
                />
                <Form.Control
                  className="mt-2 p-3"
                  type="text"
                  placeholder="English description"
                  style={changeBackgroundInput(darkMode)}
                  name={"en_description"}
                  value={addProject.en_description}
                  onChange={handleAddProjectChange}
                />
              </Form.Group>
            </Col>
            <Col lg={2} md={12} xs={12}>
              <div className="d-flex justify-content-center align-items-end h-100  button-create">
                <Link className="create-btn p-3" onClick={handleFileUpload}>
                  Create
                </Link>
              </div>
            </Col>
          </Form>
          <ToastContainer />
        </div>
      )}
    </Container>
  );
}
