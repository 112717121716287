import React from "react";
import { Row } from "react-bootstrap";
import Translation from "../../Data/Data-Welcom.json";

export default function TabelDescription({ contents, item }) {
  return (
    <Row className="notes">
      <h1 className="text-title my-4">{Translation[contents]["Tapels"]}</h1>
      <span className="span-text fs-5">{item.description}</span>
    </Row>
  );
}
