import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-tomorrow_night_bright";
import "./codemirorr.css";

const jsonData = {
  state: false,
  code: "200",
  message: "error message",
  message_code: "-1",
  execution: "0.048 seconds",
};

const ErrorsCode = ({ apiValue }) => {
  return (
    <div className="editor">
      <AceEditor
        mode="json"
        theme="tomorrow_night_bright"
        name="code-editor"
        editorProps={{ $blockScrolling: true }}
        style={{ width: "100%", height: "50vh" }}
        value={JSON.stringify(jsonData, null, 1)}
        readOnly={true}
        width={"100%"}
        // fontSize={17}
      />
    </div>
  );
};

export default ErrorsCode;
