import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import Sidebar from "react-sidebar";
import logo from "./whitelogo.png";
import "./side-toggle.css";

export default function Buttonbars({ darkMode, language, contents, data }) {
  const { projectId } = useParams();

  const [openAccordion, setOpenAccordion] = useState([]);

  const toggleAccordion = (index) => {
    if (openAccordion.includes(index)) {
      setOpenAccordion(openAccordion.filter((item) => item !== index));
    } else {
      setOpenAccordion([...openAccordion, index]);
    }
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const onSetSidebarOpen = (open) => {
    setSidebarOpen(open);
  };

  return (
    <div className="coulm">
      <Sidebar
        sidebar={
          <div>
            <div className="App-sidebare-togle">
              <img className="logo-white" alt="ss" src={logo}></img>
              <h1 className="Overview">Overview</h1>
            </div>
            {data.map((feature, featureIndex) => (
              <div style={{ width: "100%" }} key={featureIndex}>
                <Accordion>
                  <Accordion>
                    <Accordion.Header
                      onClick={() => {
                        toggleAccordion(featureIndex);
                      }}
                      className="color-font"
                    >
                      <div
                        className="d-flex justify-content-center align-items-baseline"
                        style={darkMode === true ? { color: "white" } : {}}
                      >
                        <i
                          className={`fa-solid fa-caret-right mx-3 ${
                            openAccordion.includes(featureIndex)
                              ? "rotatedis"
                              : "rotate"
                          }`}
                        ></i>
                        <p> {language ? feature.en_name : feature.ar_name}</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="d-flex justify-content-center">
                      <NavLink
                        to={`/Ourprojects/Invesment/${projectId}/Overviwe/${feature.id}`}
                        className="link-1"
                      >
                        Over view
                      </NavLink>
                    </Accordion.Body>
                    {feature.sub_features.map((subFeature, subFeatureIndex) => (
                      <div key={subFeatureIndex}>
                        <Accordion.Body className="d-flex justify-content-center">
                          <NavLink
                            to={`/Ourprojects/Invesment/${projectId}/Contract/${feature.id}/${subFeature.id}`}
                            className="link-1"
                          >
                            {language ? subFeature.en_name : subFeature.ar_name}
                          </NavLink>
                        </Accordion.Body>
                      </div>
                    ))}
                  </Accordion>
                </Accordion>
              </div>
            ))}
          </div>
        }
        open={sidebarOpen}
        onSetOpen={onSetSidebarOpen}
        pullRight={language ? false : true}
        styles={
          darkMode === true
            ? {
                sidebar: {
                  background: " #011b35",
                  width: "100%",
                  paddingTop: "20px",
                  color: "white !important",
                },
              }
            : {
                sidebar: {
                  background: "white",
                  width: "100%",
                  paddingTop: "20px",
                },
              }
        }
      >
        <button
          onClick={() => onSetSidebarOpen(true)}
          className="side-toggle"
          style={darkMode === true ? { color: "white" } : {}}
        >
          <i className="fa-solid fa-bars-staggered"></i>
        </button>
      </Sidebar>
    </div>
  );
}
