import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import appReducer from "./stores/appSlice";
import projectsSlice from "./stores/projectsSlice";
import { composeWithDevTools } from "redux-devtools-extension";

const rootReducer = combineReducers({
  app: appReducer,
  projects: projectsSlice,
});

// تحسين للاستفادة من أدوات تطوير Redux
const composeEnhancers = composeWithDevTools({
  // خيارات إضافية إذا كنت في حاجة إليها
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
