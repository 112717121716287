import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Modal from "react-modal";
import "../Styles/Projectfeatures.css";
import Loader from "../components/Loader/Loader";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams } from "react-router-dom";

export default function Projectfeatures({
  loading,
  setLoading,
  language,
  darkMode,
  showChildComponent,
  setShowChildComponent,
}) {
  const [data, setData] = useState([]);
  const { projectId } = useParams();

  useEffect(() => {
    axios
      .get(`https://etifaq.app/api/projects/${projectId}/features`)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.error("حدث خطأ:", error);
      });
  }, []);

  const notify = (message, type) => toast.success(message);
  const handleDeletefeatures = (featuresId) => {
    setLoading(true); // نقلها هنا لتظهر ال Loader قبل الطلب
    axios
      .delete(
        `https://etifaq.app/api/projects/${projectId}/features/${featuresId}`
      )
      .then((response) => {
        console.log("تم حذف العنصر بنجاح.");
        setData((prevProjects) =>
          prevProjects.filter((project) => project.id !== featuresId)
        );
        setLoading(false); // نقلها هنا لإخفاء ال Loader بعد استلام الاستجابة
        notify("تم الحذف");
      })
      .catch((error) => {
        console.error("حدث خطأ أثناء حذف العنصر:", error);
        setLoading(false); // في حالة حدوث خطأ أيضًا يجب إعادة تعيين ال Loader إلى false
      });
  };

  const handelupdatefeaturesId = (featureId) => {
    window.location.pathname = `/Ourprojects/Updateproject/Updatafeature/${projectId}/${featureId}`;
  };
  const customStyles = {
    content: {
      width: "70%",
      height: "50%",
      border: "none",
      marginLeft: "auto",
      marginTop: "10%",
      marginRight: "auto",
      borderRadius: "20px",
      background: "#0E4F6E",
    },
  };
  return (
    <Container className="d-flex flex-wrap flex-column">
      {loading && <Loader />}
      {!loading && (
        <div>
          <Row
            xs={12}
            md={12}
            className="d-flex flex-column justify-content-center align-items-center my-3"
          >
            <p className="our-project-text">Project features</p>
          </Row>

          <Row className="app-card-features">
            {data.length >= 1 ? (
              data.map((feature, index) => (
                <Card
                  key={index}
                  className="d-flex justify-content-center align-content-center flex-column card-features"
                >
                  <Card.Title className="align-icon pt-5">
                    {feature.id}
                    {language ? feature.ar_name : feature.en_name}
                  </Card.Title>
                  <Card.Text className="align-icon pt-5 pb-2">
                    <button
                      className="fa-solid fa-trash btnclick mx-1"
                      onClick={() => setShowChildComponent(!showChildComponent)}
                    ></button>
                    <button
                      className="fa-solid fa-pen btnclick-1 mx-1"
                      onClick={() => handelupdatefeaturesId(feature.id)}
                    ></button>
                  </Card.Text>
                  {showChildComponent && (
                    <Modal
                      isOpen={showChildComponent}
                      onRequestClose={() =>
                        setShowChildComponent(!showChildComponent)
                      }
                      style={customStyles}
                    >
                      <div className="d-flex justify-content-center flex-column align-items-center w-100 app-remov">
                        <div className="py-5 fs-4 row-remov">
                          Are you sure you want to remove this feature ?
                        </div>
                        <Row className="d-flex justify-content-around flex-row align-items-end py-5 w-100 text-center">
                          <Col>
                            <button
                              className="btn-style fs-5 px-5 p-1"
                              onClick={() =>
                                setShowChildComponent(!showChildComponent)
                              }
                            >
                              cancel
                            </button>
                          </Col>
                          <Col>
                            <button
                              className="btn-style fs-5 px-5 p-1"
                              onClick={() => {
                                setShowChildComponent(!showChildComponent);
                                handleDeletefeatures(feature.id);
                              }}
                            >
                              Ok
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Modal>
                  )}
                </Card>
              ))
            ) : (
              <div></div>
            )}
            <Link
              to={`/Ourprojects/Addproject/Addfeature/newfeature/${projectId}`}
              className={`d-flex justify-content-center align-content-center flex-column link_new_feature`}
            >
              <Card.Title
                className={`align-icon-white d-flex flex-column ${
                  darkMode ? "card-color" : ""
                }`}
              >
                <i className="fa-solid fa-plus mb-2"></i>
                New feature
              </Card.Title>
            </Link>
          </Row>
        </div>
      )}

      <ToastContainer />
    </Container>
  );
}

//mobile simulater
