import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { changeBackgroundInput } from "../../styleHelpers/changeBackgroundInput.js";
export default function UpdateTable({
  darkMode,
  sub_features,
  setSubFeatures,
  index,
}) {
  const [tables, setTables] = useState([
    {
      id: 1,
      title: "",
      description: "",
      columns_number: 1,
      columns_titles: ["title1"],
      rows: [{ id: 1, title1: "" }],
    },
  ]);

  const handleNumRowsChange = (e, objectIndex, index) => {
    const updatedObjects = [...sub_features];
    updatedObjects[index].tables[objectIndex].rows = Array.from(
      { length: Number(e.target.value) },
      (_, rowIndex) => {
        const rowData = { id: rowIndex + 1 };
        for (
          let colIndex = 0;
          colIndex < updatedObjects[index].tables[objectIndex].columns_number;
          colIndex++
        ) {
          const columnName = `title${colIndex + 1}`;
          rowData[columnName] = "";
        }
        return rowData;
      }
    );
    setTables(updatedObjects);
  };

  const handleNumColumnsChange = (e, objectIndex, index) => {
    const updatedObjects = [...sub_features];
    updatedObjects[index].tables[objectIndex].columns_number = Number(
      e.target.value
    );
    updatedObjects[index].tables[objectIndex].columns_titles = Array.from(
      { length: Number(e.target.value) },
      (_, colIndex) => `title${colIndex + 1}`
    );
    updatedObjects[index].tables[objectIndex].rows.forEach((row) => {
      for (
        let colIndex = 0;
        colIndex < updatedObjects[index].tables[objectIndex].columns_number;
        colIndex++
      ) {
        const columnName = `title${colIndex + 1}`;
        if (!row[columnName]) {
          row[columnName] = "";
        }
      }
    });
    setTables(updatedObjects);
  };

  const handleTableNameChange = (e, objectIndex, index) => {
    const updatedObjects = [...sub_features];
    updatedObjects[index].tables[objectIndex].title = e.target.value;
    setTables(updatedObjects);
  };

  const handleTableDescriptionChange = (e, objectIndex, index) => {
    const updatedObjects = [...sub_features];
    updatedObjects[index].tables[objectIndex].description = e.target.value;
    setTables(updatedObjects);
  };

  const handleTableDataChange = (e, objectIndex, rowIndex, colIndex, index) => {
    const updatedObjects = [...sub_features];
    updatedObjects[index].tables[objectIndex].rows[rowIndex][
      `title${colIndex + 1}`
    ] = e.target.value;
    setTables(updatedObjects);
  };

  const handleButtonClick = () => {
    const newTable = {
      id: sub_features[index].tables.length + 1,
      title: "",
      description: "",
      columns_number: 1,
      columns_titles: ["title1"],
      rows: [{ id: 1, title1: "" }],
    };

    setSubFeatures((prevSubFeatures) => {
      // استنساخ المصفوفة السابقة باستخدام spread operator
      const updatedSubFeatures = [...prevSubFeatures];

      // استنساخ الكائن الفرعي (الذي هو sub_features[0] في هذه الحالة)
      const updatedSubObject = { ...updatedSubFeatures[index] };

      // قم بإضافة الكائن الجديد إلى مصفوفة الجداول
      updatedSubObject.tables = [...updatedSubObject.tables, newTable];

      // قم بتحديث الكائن الفرعي في المصفوفة الرئيسية
      updatedSubFeatures[index] = updatedSubObject;

      // قم بإرجاع المصفوفة المحدثة
      return updatedSubFeatures;
    });
  };
  console.log(sub_features);

  console.log(sub_features);

  return (
    <div className="w-100">
      {sub_features[index]?.tables &&
        sub_features[index].tables.map((obj, objIndex) => (
          <Container
            key={objIndex}
            className="d-flex justify-content-between flex-wrap mb-3 form-style py-5 w-100"
            style={
              darkMode === true
                ? { backgroundColor: "#042230" }
                : { backgroundColor: "#EFEFEF" }
            }
          >
            <Row
              xs={12}
              md={12}
              className="d-flex flex-column justify-content-center align-items-center my-3 w-100"
            >
              <p className="our-project-text">Tables</p>
            </Row>
            <Col xs={11} md={5} lg={5} className="mx-3 mb-2 ">
              <Form.Label>Table name</Form.Label>
              <Form.Group className="mb-3">
                <Form.Control
                  style={changeBackgroundInput(darkMode)}
                  className="p-3"
                  type="text"
                  placeholder="Table name"
                  name="tableName"
                  value={obj.title}
                  onChange={(e) => handleTableNameChange(e, objIndex, index)}
                />
              </Form.Group>
            </Col>
            <Col xs={11} md={5} lg={5} className="mx-3 mb-2 ">
              <Form.Label>Table description</Form.Label>
              <Form.Group className="mb-3">
                <Form.Control
                  style={changeBackgroundInput(darkMode)}
                  className="p-3"
                  type="text"
                  placeholder="Table description"
                  name="tableDescription"
                  value={obj.description}
                  onChange={(e) =>
                    handleTableDescriptionChange(e, objIndex, index)
                  }
                />
              </Form.Group>
            </Col>
            <Col xs={11} md={5} lg={5} className="mx-3 mb-2 ">
              <Form.Label>Number of Rows:</Form.Label>
              <Form.Group className="mb-3">
                <Form.Control
                  style={changeBackgroundInput(darkMode)}
                  type="number"
                  id="numRows"
                  value={obj.rows.length}
                  onChange={(e) => handleNumRowsChange(e, objIndex, index)}
                  min={0}
                />
              </Form.Group>
            </Col>
            <Col xs={11} md={5} lg={5} className="mx-3 mb-2 ">
              <Form.Label>Number of Columns:</Form.Label>
              <Form.Group className="mb-3">
                <Form.Control
                  style={changeBackgroundInput(darkMode)}
                  type="number"
                  id="numColumns"
                  value={obj.columns_number}
                  onChange={(e) => handleNumColumnsChange(e, objIndex, index)}
                  min={0}
                />
              </Form.Group>
            </Col>
            <Container
              style={{
                overflowX: "auto",
                scrollbarGutter: "stable",
              }}
            >
              <table className="w-100">
                <thead>
                  <tr>
                    {Array.from(
                      { length: obj.columns_number },
                      (_, colIndex) => (
                        <th
                          key={colIndex}
                          style={{ minWidth: "220px", textAlign: "center" }}
                        >
                          Columns {colIndex + 1}
                        </th>
                      )
                    )}
                  </tr>
                </thead>

                <tbody>
                  {obj.rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {Array.from(
                        { length: obj.columns_number },
                        (_, colIndex) => (
                          <td key={colIndex}>
                            <Form.Group className="m-3">
                              <Form.Control
                                className="m-3"
                                style={changeBackgroundInput(darkMode)}
                                placeholder={` Row ${rowIndex + 1} Columns ${
                                  colIndex + 1
                                } `}
                                type="text"
                                value={
                                  obj.rows[rowIndex][`title${colIndex + 1}`]
                                }
                                onChange={(e) =>
                                  handleTableDataChange(
                                    e,
                                    objIndex,
                                    rowIndex,
                                    colIndex,
                                    index
                                  )
                                }
                              />
                            </Form.Group>
                          </td>
                        )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Container>
          </Container>
        ))}
      <div className="d-flex justify-content-center align-items-center w-100 ss">
        <button
          className="New-attributie-btn mt-3 mb-3 p-3 w-50"
          onClick={handleButtonClick}
        >
          New Tabel
        </button>
      </div>
    </div>
  );
}
