import React, { useState } from "react";

export default function InbutImage({ setImages }) {
  const [selectimg, setSelectimg] = useState([]);

  const onSelectFile = (event) => {
    const selectFiles = event.target.files;
    const selectedFilesArray = Array.from(selectFiles);
    const imagesArray = selectedFilesArray.map((file, index) => {
      const imageUrl = URL.createObjectURL(file);
      return imageUrl;
    });
    setSelectimg(imagesArray);
    setImages(selectFiles);
  };
  return (
    <div className="d-flex justify-content-center my-5">
      <label className="label_img">
        + Feature images
        <br />
        <input
          type="file"
          name="images"
          onChange={onSelectFile}
          multiple
          accept="image/*"
          className="input_img"
        ></input>
        <div>
          <div className="d-flex flex-wrap justify-content-center mx-2">
            {selectimg &&
              selectimg.map((img, index) => {
                return (
                  <div key={img} className="py-3 mx-2 ">
                    <img src={img} alt="ss" className="App-img" />
                  </div>
                );
              })}
          </div>
        </div>
      </label>
    </div>
  );
}
