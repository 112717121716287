import React, { useEffect, useState } from "react";
import "../Styles/invesment.css";
import { Link, useParams } from "react-router-dom";
import { Col } from "react-bootstrap";
import Translation from "../Data/Data-Welcom.json";
import { useDispatch, useSelector } from "react-redux";
import { getProject } from "../stores/projectsSlice";

export default function Invesment({ language, contents }) {
  const { projectId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProject(projectId));
  }, [dispatch, projectId]);

  const features = useSelector((state) => state.app.features);
  const getDetailsProject = useSelector((state) => state.projects.project);
  let firstId;

  if (features && features.length > 0 && features[0].id) {
    firstId = features[0].id;
    console.error("لا توجد بيانات أو البيانات غير معرفة بشكل صحيح");

  } else {
    console.error("لا توجد بيانات أو البيانات غير معرفة بشكل صحيح");
  }

  const [load, setload] = useState(true);

  const handleImageLoad = () => {
    setload(false);
  };

  return (
    <div className="d-flex justify-content-center w-100">
      <Col
        xs={12}
        md={9}
        lg={9}
        className="d-flex justify-content-center my-4  App-Invesment"
      >
        <div className="contaner-Invesment" style={{ width: "55%" }}>
          {load && <div className="loader">Loading...</div>}
          <img
            alt="s"
            src={getDetailsProject.image_url}
            className="img-Invesment"
            onLoad={handleImageLoad}
          ></img>

          <p className="title-Invesment">
            {language ? getDetailsProject.name_en : getDetailsProject.name_ar}
          </p>

          <p className="text-logos">
            {language
              ? getDetailsProject.en_description
              : getDetailsProject.ar_description}
          </p>
          <Link
            to={`/Ourprojects/Invesment/${projectId}/Overviwe/${firstId}`}
            className="get-starts w-75"
          >
            {Translation[contents]["button"]}
          </Link>
        </div>
      </Col>
    </div>
  );
}
