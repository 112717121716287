import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    data: [],
    loading: false,
    project: [],
  },
  reducers: {
    setData: (state, action) => {
      // تعيين البيانات المسترجعة من الطلب axios في الحالة
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    deleteItem: (state, action) => {
      state.data = state.data.filter(
        (project) => project.id !== action.payload
      );
    },
    setProject: (state, action) => {
      // تعيين البيانات المسترجعة من الطلب axios في الحالة
      state.project = action.payload;
    },
  },
});

export const { deleteItem, setData, setLoading, setProject } =
  projectsSlice.actions;

// إنشاء action لجلب البيانات باستخدام axios
export const fetchProjects = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.get(`https://etifaq.app/api/projects/`);
    // استخدم الإجراء المعرفة لتحديث الحالة
    dispatch(setLoading(false));
    dispatch(setData(response.data.data));
    console.log(response.data.data)

  } catch (error) {
    dispatch(setLoading(false));
    console.error("Error fetching data:", error);
  }
};

export const deleteProject = (projectId) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .delete(`https://etifaq.app/api/projects/${projectId}`)
    .then((response) => {
      dispatch(setLoading(false));
      dispatch(deleteItem(projectId));
    })
    .catch((error) => {
      dispatch(setLoading(false));
      console.error("حدث خطأ أثناء حذف العنصر:", error);
    });
};

export const getProject = (projectId) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const response =  await axios.get(
      `https://etifaq.app/api/projects/${projectId}`
    );
    console.log(response.data.data)

    dispatch(setLoading(false));
    dispatch(setProject(response.data.data));

  } catch (error) {
    dispatch(setLoading(false));
    console.error("حدث خطأ أثناء جلب البيانات:", error);
  }
};

export default projectsSlice.reducer;
