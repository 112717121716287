// ModalComponent.js
import React from "react";
import Modal from "react-modal";
import { deleteProject } from "../../stores/projectsSlice";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

export default function ModalComponent({
  showChildComponent,
  setShowChildComponent,
  selectedProjectId,
}) {
  const dispatch = useDispatch();

  const customStyles = {
    content: {
      width: "70%",
      height: "50%",
      border: "none",
      marginLeft: "auto",
      marginTop: "10%",
      marginRight: "auto",
      borderRadius: "20px",
      background: "#0E4F6E",
    },
  };
  return (
    <Modal
      isOpen={showChildComponent}
      onRequestClose={() => setShowChildComponent(!showChildComponent)}
      style={customStyles}
    >
      <div className="d-flex justify-content-center flex-column align-items-center w-100 app-remov">
        <div className="py-5 fs-4 row-remov">
          Are you sure you want to remove project ?
        </div>
        <Row className="d-flex justify-content-around flex-row align-items-end py-5 w-100 text-center">
          <Col>
            <button
              className="btn-style fs-5 px-5 p-1"
              onClick={() => setShowChildComponent(!showChildComponent)}
            >
              cancel
            </button>
          </Col>
          <Col>
            <button
              className="btn-style fs-5 px-5 p-1"
              onClick={() => {
                dispatch(deleteProject(selectedProjectId));
                setShowChildComponent(!showChildComponent);
              }}
            >
              Ok
            </button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
