import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    features: [],
  },
  reducers: {
    setFeatures: (state, action) => {
      state.features = action.payload;
    },
  },
});

export const { setFeatures } = appSlice.actions;

// إنشاء action لجلب البيانات باستخدام axios
export const fetchFeatures = (projectId) => async (dispatch) => {
  try {
    const response = await axios.get(
      `https://etifaq.app/api/projects/${projectId}/features`
    );
    // استخدم الإجراء المعرفة لتحديث الحالة


    dispatch(setFeatures(response.data.data));
    console.log(response.data.data)
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export default appSlice.reducer;
