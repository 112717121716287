import React from "react";
import { Link } from "react-router-dom";

export default function UpdateBtn({ projects }) {
  return (
    <Link to={`/Ourprojects/${projects.id}`}>
      <button
        className="fa-solid fa-pen btnclick-2 mx-1 "
        onClick={() => {
          window.location.pathname = `/Ourprojects/${projects.id}`;
        }}
      ></button>
    </Link>
  );
}
