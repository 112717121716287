import React from "react";
import "../Styles/welcom-screen.css";
import logo from "../images/logo1.png";
import { Link } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import Translation from "../Data/Data-Welcom.json";

export default function Welcoming_screen({ contents }) {
  return (
    <Container className="d-flex flex-column welcom-page">
      <Row
        xs={12}
        md={12}
        className="d-flex flex-column justify-content-center align-items-center my-1"
      >
        <img src={logo} alt="k" className="logo "></img>
      </Row>
      <Row
        xs={12}
        md={12}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <div>
          <p className="text-logo">
            {Translation[contents]["title"]}
            <br></br> {Translation[contents]["completetitle"]}
          </p>
        </div>
      </Row>
      <div className="get-start-btn my-2">
        <Link to={"/Ourproject"} className="get-start">
          {Translation[contents]["button"]}
        </Link>
        <Link to={"/Ourprojects"} className="get-start mx-3">
          {Translation[contents]["buttonAdmin"]}
        </Link>
      </div>
    </Container>
  );
}
