export const addTool = (parentToolId, tool, newId, state) => {
  state.map((element) => {
    if (element.id == parentToolId) {
      element.children.push({
        id: newId,
        ...tool,
      });
      return true;
    } else {
      if (element.children)
        for (let i = 0; i < element.children.length; i++) {
          if (addTool(parentToolId, tool, newId, element.children)) return;
        }
    }
  });
};
