import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import { addTool } from "../Tools";

export default function Updateattributiess(props) {
  const [inccemrntId, setInccemrntId] = useState(1);

  const handleDelete = (idToRemove) => {
    props.setAttributies((prevAttributes) =>
      removeAttribute(prevAttributes, idToRemove)
    );
  };
  function removeAttribute(attributes, idToRemove) {
    return attributes.filter((attribute) => {
      if (attribute.id === idToRemove) {
        return false; // Remove the attribute with the specified id
      }
      // Recursively remove the attribute from its children
      attribute.children = removeAttribute(attribute.children, idToRemove);
      return true;
    });
  }

  const handleAddFields = () => {
    const values = [...props.attributes];
    values.push({
      id: inccemrntId,
      name: "",
      type: "",
      isRequired: "",
      ar_description: "",
      en_description: "",
      children: [],
    });
    props.setAttributies(values);
    setInccemrntId((prev) => prev + 1);
    // زيادة الانزياح وتغيير اللون
    setColorIndex((prev) => (prev + 1) % lineColors.length);
  };

  const handleInputChange = (childId, event, parentId) => {
    const { name, value } = event.target;

    // Helper function for recursive search and update
    const updateObject = (obj) => {
      if (obj.id === childId) {
        // Found the child object, update its attribute
        obj[name] = value;

        return true;
      }

      if (obj.children && obj.children.length > 0) {
        // Recursively search and update in the children
        for (let childObj of obj.children) {
          if (updateObject(childObj)) {
            return true;
          }
        }
      }

      return false;
    };

    // Start the recursive search
    const updatedChildren = [...props.attributes];
    updatedChildren.map((child) => {
      updateObject(child);
    });

    props.setAttributies(updatedChildren); // Update the state
  };

  const [indentLevel, setIndentLevel] = useState(0); // تتبع الانزياح
  const [colorIndex, setColorIndex] = useState(0);
  const lineColors = [props.darkMode ? "#0e4f6e " : "#EFEFEF"];

  const handleAddSubAttribute = (event, parentId) => {
    event.preventDefault();
    const values = [...props.children];
    const objChiled = {
      id: inccemrntId,
      name: "",
      type: "",
      isRequired: "",
      ar_description: "",
      en_description: "",
      children: [],
    };
    addTool(parentId, objChiled, inccemrntId, props.attributes);
    setInccemrntId((prev) => prev + 1);
    setColorIndex((prev) => (prev + 1) % lineColors.length);
  };

  // console.log(props.attributes);

  const renderNestedChildren = (children, parentId, level) => {
    return children.map((inputField, index) => (
      <div
        style={{
          marginLeft: `${5 + indentLevel * 2}px`,
          borderLeft: `4px solid ${getColor(level)}`,
          paddingLeft: "10px",
        }}
        key={index}
      >
        <div
          className={props.darkMode ? "horizontal-line-1" : "horizontal-line"}
          style={{ backgroundColor: `${getColor(level)}` }}
        >
          <div>
            <div
              className="d-flex flex-wrap w-100 mb-2 justify-content-center "
              style={
                props.darkMode === true
                  ? { backgroundColor: "#042230", borderRadius: "10px" }
                  : { backgroundColor: "#EFEFEF", borderRadius: "10px" }
              }
            >
              <div className="d-flex justify-content-between flex-wrap  mb-3 form-style">
                <Col
                  xs={11}
                  md={12}
                  lg={12}
                  className="d-flex justify-content-end w-100 mb-2"
                >
                  <button
                    className=" button-close"
                    onClick={(e) => {
                      handleDelete(inputField.id);
                      e.preventDefault();
                    }}
                  >
                    <i className="fa-solid fa-x"></i>
                  </button>
                </Col>
                <Col xs={11} md={5} lg={5} className="mx-3 mb-2">
                  <Form.Group className="mb-3 ">
                    <Form.Control
                      className="p-3"
                      type="text"
                      placeholder="Attributie name"
                      style={
                        props.darkMode
                          ? { backgroundColor: " rgb(2, 36, 52)" }
                          : { backgroundColor: "white" }
                      }
                      name={"name"}
                      value={inputField.name}
                      onChange={(event) =>
                        handleInputChange(inputField.id, event)
                      }
                    />
                  </Form.Group>
                </Col>

                <Col xs={11} md={5} lg={5} className="mx-3 d-flex mb-3">
                  <Form.Select
                    style={
                      props.darkMode
                        ? { backgroundColor: " rgb(2, 36, 52)" }
                        : { backgroundColor: "white" }
                    }
                    name={"type"}
                    value={inputField.type}
                    onChange={(event) =>
                      handleInputChange(inputField.id, event)
                    }
                  >
                    <option value="">Select an object</option>
                    <option value="object">Object</option>
                    <option value="List">List</option>
                  </Form.Select>

                  <Form.Select
                    className="mx-5"
                    style={
                      props.darkMode
                        ? { backgroundColor: " rgb(2, 36, 52)" }
                        : { backgroundColor: "white" }
                    }
                    name={"isRequired"}
                    value={inputField.isRequired}
                    onChange={(event) =>
                      handleInputChange(inputField.id, event)
                    }
                  >
                    <option>Requierd</option>
                  </Form.Select>
                </Col>

                <Col xs={11} md={5} lg={5} className="mx-3">
                  <Form.Group className="mb-3">
                    <Form.Control
                      className="p-3"
                      style={
                        props.darkMode
                          ? { backgroundColor: " rgb(2, 36, 52)" }
                          : { backgroundColor: "white" }
                      }
                      type="text"
                      placeholder="Arabic attributie description"
                      name={"ar_description"}
                      value={inputField.ar_description}
                      onChange={(event) =>
                        handleInputChange(inputField.id, event)
                      }
                    />
                  </Form.Group>
                </Col>

                <Col xs={11} md={5} lg={5} className="mx-3">
                  <Form.Group className="mb-3">
                    <Form.Control
                      className="p-3"
                      style={
                        props.darkMode
                          ? { backgroundColor: " rgb(2, 36, 52)" }
                          : { backgroundColor: "white" }
                      }
                      type="text"
                      placeholder="English attributie description"
                      name={"en_description"}
                      value={inputField.en_description}
                      onChange={(event) =>
                        handleInputChange(inputField.id, event)
                      }
                    />
                  </Form.Group>
                </Col>
              </div>
              {(inputField.type === "object" || inputField.type === "List") && (
                <div>
                  <button
                    onClick={(e) => handleAddSubAttribute(e, inputField.id)}
                    className="new-payload mb-3 "
                  >
                    + Add sub attribute
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {inputField.children &&
          inputField.children.length > 0 &&
          renderNestedChildren(inputField.children, inputField.id, level + 1)}
      </div>
    ));
  };
  const getColor = (level) => {
    const colors = [
      props.darkMode ? "#0e4f6e " : "#EFEFEF", // لون للمستوى 0
      "red", // لون للمستوى 1
      "yellow", // لون للمستوى 2
      // يمكنك إضافة المزيد من الألوان إذا كنت ترغب في دعم مستويات أعمق
    ];
    const colorIndex = level % colors.length;
    return colors[colorIndex];
  };

  return (
    <div>
      {props.data &&
        props.attributes &&
        props.attributes.map((inputField, index) => (
          <div
            style={{
              borderLeft: `4px solid ${getColor(0)}`, // استخدام لون المستوى الأول هنا
              paddingLeft: "10px",
              marginTop: "20px",
            }}
            key={index}
          >
            <div
              className={
                props.darkMode ? "horizontal-line-1" : "horizontal-line"
              }
              style={{ backgroundColor: `${getColor(0)}` }}
            >
              <div>
                <div
                  className="d-flex flex-wrap w-100 mb-2 justify-content-center "
                  style={
                    props.darkMode === true
                      ? { backgroundColor: "#042230", borderRadius: "10px" }
                      : { backgroundColor: "#EFEFEF", borderRadius: "10px" }
                  }
                >
                  <div className="d-flex justify-content-between flex-wrap  mb-3 form-style">
                    <Col
                      xs={11}
                      md={12}
                      lg={12}
                      className="d-flex justify-content-end w-100 mb-2"
                    >
                      <button
                        className=" button-close"
                        onClick={(e) => {
                          handleDelete(inputField.id);
                          e.preventDefault();
                        }}
                      >
                        <i className="fa-solid fa-x"></i>
                      </button>
                    </Col>
                    <Col xs={11} md={5} lg={5} className="mx-3 mb-2">
                      <Form.Group className="mb-3 ">
                        <Form.Control
                          className="p-3"
                          type="text"
                          placeholder="Attributie name"
                          style={
                            props.darkMode
                              ? { backgroundColor: " rgb(2, 36, 52)" }
                              : { backgroundColor: "white" }
                          }
                          name={"name"}
                          value={inputField.name}
                          onChange={(event) =>
                            handleInputChange(inputField.id, event)
                          }
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={11} md={5} lg={5} className="mx-3 d-flex mb-3">
                      <Form.Select
                        style={
                          props.darkMode
                            ? { backgroundColor: " rgb(2, 36, 52)" }
                            : { backgroundColor: "white" }
                        }
                        name={"type"}
                        value={inputField.type}
                        onChange={(event) =>
                          handleInputChange(inputField.id, event)
                        }
                      >
                        <option value="">Select an object</option>
                        <option value="object">Object</option>
                        <option value="List">List</option>
                      </Form.Select>

                      <Form.Select
                        className="mx-5"
                        style={
                          props.darkMode
                            ? { backgroundColor: " rgb(2, 36, 52)" }
                            : { backgroundColor: "white" }
                        }
                        name={"isRequired"}
                        value={inputField.isRequired}
                        onChange={(event) => handleInputChange(index, event)}
                      >
                        <option>Requierd</option>
                      </Form.Select>
                    </Col>

                    <Col xs={11} md={5} lg={5} className="mx-3">
                      <Form.Group className="mb-3">
                        <Form.Control
                          className="p-3"
                          style={
                            props.darkMode
                              ? { backgroundColor: " rgb(2, 36, 52)" }
                              : { backgroundColor: "white" }
                          }
                          type="text"
                          placeholder="Arabic attributie description"
                          name={"ar_description"}
                          value={inputField.ar_description}
                          onChange={(event) =>
                            handleInputChange(inputField.id, event)
                          }
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={11} md={5} lg={5} className="mx-3">
                      <Form.Group className="mb-3">
                        <Form.Control
                          className="p-3"
                          style={
                            props.darkMode
                              ? { backgroundColor: " rgb(2, 36, 52)" }
                              : { backgroundColor: "white" }
                          }
                          type="text"
                          placeholder="English attributie description"
                          name={"en_description"}
                          value={inputField.en_description}
                          onChange={(event) =>
                            handleInputChange(inputField.id, event)
                          }
                        />
                      </Form.Group>
                    </Col>
                  </div>
                  {(inputField.type === "object" ||
                    inputField.type === "List") && (
                    <div>
                      <button
                        onClick={(e) => handleAddSubAttribute(e, inputField.id)}
                        className="new-payload mb-3 "
                      >
                        + Add sub attribute
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {inputField.children &&
              inputField.children.length > 0 &&
              renderNestedChildren(inputField.children, inputField.id, 1)}
          </div>
        ))}

      <div className="ss d-flex justify-content-center">
        <button
          type="button"
          onClick={() => handleAddFields()}
          className="New-attributie-btn mt-3 mb-3 p-3"
        >
          New Attributies
        </button>
      </div>
    </div>
  );
}
