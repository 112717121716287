import React from "react";
import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";

export default function TabelShow({ item }) {
  return (
    <Container>
      <Table className="tabel-app ">
        <thead className="head">
          <tr>
            {item.length > 0 ? (
              Object.keys(item[0]).map((key) => (
                <th key={key}>{item[0][key]}</th>
              ))
            ) : (
              <div></div>
            )}
          </tr>
        </thead>
        <tbody className="body">
          {item.slice(1).map((items, index) => (
            <tr key={index}>
              {Object.keys(items).map((key) => (
                <td key={key}>{items[key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
