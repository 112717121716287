import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../Styles/Newfeature.css";
import Loader from "../components/Loader/Loader";
import Updateattributiess from "../components/Updatefeature/Updateattributiess";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { changeBackgroundInput } from "../styleHelpers/changeBackgroundInput.js";
import InputImages from "../components/Updatefeature/InputImages";

export default function Updatafeature(props) {
  const [data, setData] = useState([]);
  const { projectId, featureId } = useParams();

  useEffect(() => {
    axios
      .get(
        `https://etifaq.app/api/projects/${projectId}/features/${featureId}`
      )
      .then((response) => {
        setData(response.data.data);
        props.setAttributies(response.data.data.attributes);
        props.setSubFeatures(response.data.data.sub_features);
        props.images_ids.current = response.data.data.image_ids;
      })
      .catch((error) => {
        console.error("حدث خطأ:", error);
      });
    console.log(data);
  }, []);

  useEffect(() => {
    props.setArabicFeatureName(data.ar_name);
    props.setEnglishFeatureName(data.en_name);
    props.setArabicDescription(data.ar_description);
    props.setEnglishDescription(data.en_description);
  }, [data]);

  return (
    <Container>
      {props.loading && <Loader />}
      {!props.loading && (
        <Form style={changeBackgroundInput(props.darkMode)}>
          <Row
            xs={12}
            md={12}
            className="d-flex flex-column justify-content-center align-items-center my-3"
          >
            <p className="our-project-text">Update feature</p>
          </Row>
          <InputImages setImages={props.setImages} data={data} />
          <div className="d-flex justify-content-between flex-wrap my-5">
            <Col xs={11} md={5} lg={5} className="mx-3">
              <Form.Group className="mb-3 ">
                <Form.Control
                  className="p-3"
                  type="text"
                  placeholder="Arabic feature name"
                  style={changeBackgroundInput(props.darkMode)}
                  value={props.ar_name || ""}
                  onChange={(e) => props.setArabicFeatureName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xs={11} md={5} lg={5} className="mx-3">
              <Form.Group className="mb-3">
                <Form.Control
                  className="p-3"
                  type="text"
                  placeholder="English feature name"
                  style={changeBackgroundInput(props.darkMode)}
                  value={props.en_name || ""}
                  onChange={(e) => props.setEnglishFeatureName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xs={11} md={5} lg={5} className="mx-3">
              <Form.Group className="mb-3">
                <Form.Control
                  className="p-3"
                  type="text"
                  placeholder="Arabic description"
                  style={changeBackgroundInput(props.darkMode)}
                  value={props.ar_description || ""}
                  onChange={(e) => props.setArabicDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xs={11} md={5} lg={5} className="mx-3">
              <Form.Group className="mb-3">
                <Form.Control
                  className="p-3"
                  type="text"
                  placeholder="English description"
                  style={changeBackgroundInput(props.darkMode)}
                  value={props.en_description || ""}
                  onChange={(e) => props.setEnglishDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </div>
          <Row
            xs={12}
            md={12}
            className="d-flex flex-column justify-content-center align-items-center my-3"
          >
            <p className="our-project-text">Attributies</p>
          </Row>
          <div className="d-flex justify-content-between flex-wrap my-5">
            <Col
              xs={6}
              md={6}
              lg={6}
              className="d-flex justify-content-center align-items-center w-100"
            >
              <Updateattributiess
                darkMode={props.darkMode}
                changeBackgroundInput={changeBackgroundInput}
                attributes={props.attributes}
                setAttributies={props.setAttributies}
                test={props.test}
                children={props.children}
                setChildren={props.setChildren}
                data={data}
              />
            </Col>
          </div>
          <div className="d-flex justify-content-between flex-wrap  w-100">
            <Link
              to={`/Ourprojects/Updateproject/Updatafeature/UpdateAffilientfeature/${projectId}/${featureId}`}
              className="New-attributie-btn mt-3 mb-3 p-3 w-100 mb-5"
            >
              Next
            </Link>
          </div>
        </Form>
      )}
    </Container>
  );
}
