// Overviwe.js
import React, { useEffect, useState } from "react";
import "../Styles/overviwe.css";
import Slider from "../components/Overviwe/Slider";
import { Col, Container, Row } from "react-bootstrap";
import OverviweCodeObject from "../components/Overviwe/OverviweCodeObject";
import Modal from "react-modal";
import Translation from "../Data/Data-Welcom.json";
import CardAttribute from "../components/Overviwe/CardAttribute";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Overviwe({ darkMode, language, contents, activeData }) {
  const changeColorBody = () => {
    return darkMode === false ? "card-body" : "card-body-dark";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showChildComponent, setShowChildComponent] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState(null);

  const customStyles = {
    content: {
      width: "90%",
      border: "none",
      marginLeft: "auto",
      marginRight: "auto",
      borderRadius: "20px",
      background: "none",
    },
  };
  // const data = useSelector((state) => state.app.data);

  const features = useSelector((state) => state.app.features);

  const { featureId } = useParams();
  const selectedFeature = features.filter((f) => f.id == featureId)[0];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeData]);

  const handleAttributeClick = (attribute) => {
    setSelectedAttribute(attribute);
    setShowChildComponent(true);
  };

  return (
    <div
      className="d-flex justify-content-between  app-over"
      style={{ width: "90%" }}
    >
      {selectedFeature && (
        <Container>
          <Col xs={12} md={12} lg={12}>
            <Row className="mx-1">
              <Slider data={selectedFeature} />
            </Row>
            <Row className="notes">
              <div className=" overview-text-app my-4 px-4">
                <div className="text-margin">
                  <h1 className="text-title">
                    {Translation[contents]["Overview"]}
                  </h1>
                  <span className="span-text">
                    {language
                      ? selectedFeature.en_description
                      : selectedFeature.ar_description}
                  </span>
                </div>
              </div>
            </Row>
            <Row>
              <h1 className="text-title my-5 px-4">
                {Translation[contents]["Attributies"]}
              </h1>
            </Row>

            <Row>
              <div className="req-and-op my-2">
                <div className="d-flex justify-content-around align-items-center mx-1">
                  <div className="red-cir "></div>
                  <span className="Required fs-4">
                    {Translation[contents]["Required"]}
                  </span>
                </div>

                <div className="d-flex justify-content-around align-items-center mx-5">
                  <div className="yellow-cir "></div>
                  <span className="Optional fs-4">
                    {Translation[contents]["Optional"]}
                  </span>
                </div>
              </div>
            </Row>

            <div
              className="card-container"
              style={{
                display: "flex",
                justifyContent: "start",
                flexWrap: "wrap",
              }}
            >
              {selectedFeature.attributes.map((attribute, index) => (
                <CardAttribute
                  language={language}
                  setShowChildComponent={handleAttributeClick}
                  showChildComponent={showChildComponent}
                  changeColorBody={changeColorBody}
                  attribute={attribute}
                  key={index}
                />
              ))}
            </div>

            {showChildComponent && selectedAttribute && (
              <Modal
                isOpen={showChildComponent}
                onRequestClose={() => setShowChildComponent(false)}
                style={customStyles}
              >
                <div className="card-component">
                  <OverviweCodeObject
                    language={language}
                    darkMode={darkMode}
                    attribute={selectedAttribute}
                  />
                </div>
              </Modal>
            )}
          </Col>
        </Container>
      )}
    </div>
  );
}
