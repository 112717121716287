import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../Styles/Newfeature.css";
import { Link, useParams } from "react-router-dom";
import Attributiess from "../components/Newfeature/Attributiess";
import Loader from "../components/Loader/Loader";
import { changeBackgroundInput } from "../styleHelpers/changeBackgroundInput.js";
import InputImage from "../components/Newfeature/InbutImage";

export default function Newfeature({
  darkMode,
  attributes,
  setAttributies,
  ar_name,
  setArabicFeatureName,
  en_name,
  setEnglishFeatureName,
  ar_description,
  setArabicDescription,
  en_description,
  setEnglishDescription,
  setImages,
  test,
  children,
  setChildren,
  loading,
  images,
}) {
  // const onDeleteImage = (index) => {
  //   const updatedImages = [...selectimg];
  //   updatedImages.splice(index, 1);
  //   setSelectimg(updatedImages);

  //   const updatedFiles = [...images];
  //   updatedFiles.splice(index, 1);
  //   setImages(updatedFiles);

  //   <button
  //   className="delete-image-btn"
  //   onClick={() => onDeleteImage(index)}
  // >
  //   X
  // </button>
  // };

  const { projectId } = useParams();

  return (
    <Container className="d-flex flex-wrap flex-column ">
      {loading && <Loader />}
      {!loading && (
        <Form style={darkMode === true ? { backgroundColor: "#022434" } : {}}>
          <Row
            xs={12}
            md={12}
            className="d-flex flex-column justify-content-center align-items-center my-3"
          >
            <p className="our-project-text">Overviwe</p>
          </Row>

          <InputImage setImages={setImages} />
          <div className="d-flex justify-content-between flex-wrap my-5">
            <Col xs={11} md={5} lg={5} className="mx-3">
              <Form.Group className="mb-3 ">
                <Form.Control
                  className="p-3"
                  type="text"
                  placeholder="Arabic feature name"
                  style={changeBackgroundInput(darkMode)}
                  value={ar_name}
                  onChange={(e) => setArabicFeatureName(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col xs={11} md={5} lg={5} className="mx-3">
              <Form.Group className="mb-3">
                <Form.Control
                  className="p-3"
                  type="text"
                  placeholder="English feature name"
                  style={changeBackgroundInput(darkMode)}
                  value={en_name}
                  onChange={(e) => setEnglishFeatureName(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col xs={11} md={5} lg={5} className="mx-3">
              <Form.Group className="mb-3">
                <Form.Control
                  className="p-3"
                  type="text"
                  placeholder="Arabic description"
                  style={changeBackgroundInput(darkMode)}
                  value={ar_description}
                  onChange={(e) => setArabicDescription(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col xs={11} md={5} lg={5} className="mx-3">
              <Form.Group className="mb-3">
                <Form.Control
                  className="p-3"
                  type="text"
                  placeholder="English description"
                  style={changeBackgroundInput(darkMode)}
                  value={en_description}
                  onChange={(e) => setEnglishDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </div>

          <Row
            xs={12}
            md={12}
            className="d-flex flex-column justify-content-center align-items-center my-3"
          >
            <p className="our-project-text">Attributies</p>
          </Row>

          <div className="d-flex justify-content-between flex-wrap my-5">
            <Col
              xs={6}
              md={6}
              lg={6}
              className="d-flex justify-content-center align-items-center w-100"
            >
              <Attributiess
                darkMode={darkMode}
                changeBackgroundInput={changeBackgroundInput}
                attributes={attributes}
                setAttributies={setAttributies}
                test={test}
                children={children}
                setChildren={setChildren}
              />
            </Col>
          </div>

          <div className="d-flex justify-content-between flex-wrap  w-100">
            <Link
              to={`/Ourprojects/Addproject/Addfeature/newfeature/Affilientfeature/${projectId}`}
              className="New-attributie-btn mt-3 mb-3 p-3 w-100 mb-5"
            >
              Next
            </Link>
          </div>
        </Form>
      )}
    </Container>
  );
}
