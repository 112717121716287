// NoProjectComponent.js
import React from "react";
import noProjectImage from "./images/noproject.png";
export default function NoProjectComponent() {
  return (
    <div className="ssss">
      <div className=" sss">
        <h1>No project yet</h1>
        <img src={noProjectImage} alt="" className="w-50 h-100" />
      </div>
      <div className=" add_one">
        <button
          className="btn-add-one"
          onClick={() => (window.location.pathname = "/Ourprojects/Addproject")}
        >
          Add one
        </button>
      </div>
    </div>
  );
}
