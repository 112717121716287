import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../Styles/addcontract.css";
import CodeEditor from "../components/Addcontract/CodeMirorr";
import ErrorsCode from "../components/Addcontract/ErrorsCode";
import Api from "../components/Addcontract/Api";
import Translation from "../Data/Data-Welcom.json";
import TabelShow from "../components/Addcontract/TabelShow";
import TabelDescription from "../components/Addcontract/TabelDescription";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

export default function AddContract({
  darkMode,
  language,
  contents,
  activeData,
}) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const goNext = () => {
    navigate(+1);
  };
  const changeColorFont = () => {
    if (darkMode === false) {
      return { color: "#011B35" };
    } else {
      return { color: "white", backgroundColor: "#022434" };
    }
  };

  const features = useSelector((state) => state.app.features);

  const { featureId } = useParams();
  const feat = features.find((f) => f.id == featureId);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [activeData]);

  const { subFeatureId } = useParams();

  const selectedSubFeature = feat?.sub_features.find(
    (subFeat) => subFeat.id == subFeatureId
  );

  return (
    <div className="d-flex justify-content-between w-75 app-over ">
      <Container>
        <Col xs={12} md={12} lg={9} className="mx-lg-4 col-app">
          <Row>
            <div className="overview-text-app my-4">
              {selectedSubFeature && (
                <div>
                  <h1 className="text-title fs-1">
                    {language
                      ? selectedSubFeature.en_name
                      : selectedSubFeature.ar_name}
                  </h1>
                  <span className="span-text fs-5">
                    {language
                      ? selectedSubFeature.en_description
                      : selectedSubFeature.ar_description}
                  </span>
                </div>
              )}
            </div>
          </Row>

          <Row xs={12} md={8} lg={9} className="app-notes">
            <div className=" overview-text-app my-4">
              <Row>
                {feat && feat.sub_features && feat.sub_features.length > 0 && (
                  <Api
                    language={language}
                    subFeatureId={subFeatureId}
                    apiValue={feat.sub_features.find(
                      (subFeat) => subFeat.id == subFeatureId
                    )}
                  />
                )}
              </Row>

              <Row className="my-5">
                <h1 className="text-title">{Translation[contents]["Error"]}</h1>
                <ErrorsCode
                // apiValue={feat.api}
                />
              </Row>

              <Row>
                <h1 className="text-title">
                  {Translation[contents]["Response"]}
                </h1>
                <CodeEditor />
              </Row>

              <Row className="notes">
                <h1 className="text-title my-4">
                  {Translation[contents]["Notes"]}
                </h1>
                {feat && (
                  <span className="span-text fs-5">
                    {language
                      ? selectedSubFeature.en_note
                      : selectedSubFeature.ar_note}
                  </span>
                )}
              </Row>

              {selectedSubFeature && selectedSubFeature.tables ? (
                selectedSubFeature.tables.map((item, index) => (
                  <div key={index}>
                    <TabelDescription contents={contents} item={item} />
                    <div className="my-5">
                      <TabelShow item={item.rows} />
                    </div>
                  </div>
                ))
              ) : (
                <div></div>
              )}

              <Row>
                <div className="d-flex justify-content-center dir my-5">
                  <button className="back-btn my-1 mx-4" onClick={goNext}>
                    <i className="fa-solid fa-reply icon-rotat"></i>
                    <span className="back-text " style={changeColorFont()}>
                      {Translation[contents]["Next"]}
                    </span>
                  </button>
                  <button className="back-btn my-1 mx-4" onClick={goBack}>
                    <span className=" back-text" style={changeColorFont()}>
                      {Translation[contents]["Back"]}
                    </span>
                    <i className="fa-solid fa-reply icon"></i>
                  </button>
                </div>
              </Row>
            </div>
          </Row>
        </Col>
      </Container>
    </div>
  );
}
