// OverviweCodeObject.js
import React from "react";
import { Card, Container, Row } from "react-bootstrap";
import "./overviweCodeObject.css";

export default function OverviweCodeObject({ darkMode, attribute, language }) {
  const changeBackGround = () => {
    return darkMode === false ? "card-dark-mode" : "card-light-mode";
  };

  const changeColorFont = () => {
    return darkMode === false ? { color: "#022434" } : { color: "white" };
  };

  const renderChildren = (children) => {
    return children.map((child, index) => (
      <div key={index}>
        <Card className={changeBackGround()}>
          <Card.Title className="mx-5 mt-5 mb-3 fs-5 code-title-object">
            {child.type}
          </Card.Title>
          <p className="mx-5 fs-5 mb-5" style={changeColorFont()}>
            {language ? child.en_description : child.ar_description}
          </p>
        </Card>
        {child.children && child.children.length > 0 && (
          <div className="nested-children">
            {renderChildren(child.children)}
          </div>
        )}
      </div>
    ));
  };

  return (
    <Container
      style={
        darkMode === true
          ? { backgroundColor: "#0E4F6E", borderRadius: "40px" }
          : { backgroundColor: "#E6E0E0", borderRadius: "40px" }
      }
      className="d-flex flex-column justify-content-center align-items-center my-3"
    >
      <Row
        className="d-flex justify-content-center align-items-center mt-5"
        style={{ width: "80%" }}
      >
        {attribute.children && renderChildren(attribute.children)}
      </Row>
    </Container>
  );
}
