import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../Styles/Addproject.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { changeBackgroundInput } from "../styleHelpers/changeBackgroundInput.js";
import { useDispatch, useSelector } from "react-redux";
import { getProject } from "../stores/projectsSlice";

export default function Updateproject({ darkMode, loading, setLoading }) {
  const [selectedImage, setSelectedImage] = useState();
  const [imageSelected, setImageSelected] = useState();
  const { projectId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProject(projectId));
  }, [dispatch, projectId]);

  const getDetailsProject = useSelector((state) => state.projects.project);

  var [addProject, setAddProject] = useState({
    id: 1,
    ar_name: "",
    en_name: "",
    ar_description: "",
    en_description: "",
    image_url: "",
    image_id: 1,
  });

  useEffect(() => {
    setAddProject((prevState) => ({
      ...prevState,
      ar_name: getDetailsProject ? getDetailsProject.name_ar : '',
      en_name: getDetailsProject ? getDetailsProject.name_en : '',
      ar_description: getDetailsProject ? getDetailsProject.ar_description : '',
      en_description: getDetailsProject ? getDetailsProject.en_description : '',
      image_url: getDetailsProject ? getDetailsProject.image_url : '',
      image_id: getDetailsProject ? getDetailsProject.image_id : '',
    }));
  }, [getDetailsProject]);

  const handleAddProjectChange = (e) => {
    const { name, value } = e.target;
    setAddProject((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const { file, value } = event.target;
    setAddProject((prevState) => ({
      ...prevState,
      [file]: value,
    }));
    setSelectedImage(event.target.files[0]);
    setImageSelected(URL.createObjectURL(event.target.files[0]));
  };

  const handleFileUpload = async () => {
    setLoading(true);
    if (imageSelected) {
      const formData = new FormData();
      formData.append("file", selectedImage);

      const config_file = {
        method: "post",
        url: "https://etifaq.app/api/files",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "multipart/from-data",
          "content-length": `${selectedImage.size}`,
          Authorization: "Bearer 1734|WZFIBiz63fnnhWryRxse1iE7fGKlG4kcjGOtUrzg",
        },
        data: formData,
      };

      try {
        const img_response = await axios.request(config_file);
        addProject.image_id = img_response.data.data.id;
      } catch (error) {
        console.log(error);
      }
    }

    // إرسال البيانات الأخرى
    const projectConfig = {
      method: "put",
      url: `https://etifaq.app/api/projects/${projectId}`,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        Authorization: "Bearer 1734|WZFIBiz63fnnhWryRxse1iE7fGKlG4kcjGOtUrzg",
        "X-CSRF-TOKEN": "",
      },
      data: addProject,
    };

    try {
      await axios.request(projectConfig);
      console.log("Project updated successfully");
    } catch (error) {
      console.log(error);
    }

    window.location.pathname = `/Ourprojects/Addproject/Addfeature/newfeature/Projectfeatures/${projectId}`;
    setLoading(false);
  };
  console.log(addProject);
  return (
    <Container className="d-flex flex-column ">
      {loading && <Loader />}
      {!loading && (
        <div>
          <Row
            xs={12}
            md={12}
            className="d-flex flex-column justify-content-center align-items-center my-3"
          >
            <p className="our-project-text">Update project</p>
          </Row>
          <Form className="d-flex flex-wrap justify-content-between   width-form">
            <Col lg={3} md={12} xs={12}>
              <div className="new-project">
                <div className="link-card">
                  <div>
                    <label htmlFor="image-upload" className="upload-btn">
                      {imageSelected ? (
                        <img
                          src={imageSelected}
                          alt="preview"
                          className="preview-image"
                          style={{ width: "90%", maxHeight: "300px" }}
                        />
                      ) : (
                        <img
                          src={getDetailsProject.image_url}
                          alt="preview"
                          className="preview-image"
                          style={{ width: "90%", maxHeight: "300px" }}
                        />
                      )}
                    </label>
                    <input
                      type="file"
                      id="image-upload"
                      value={''}
                      accept="image/*"
                      name={"image_url"}
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={6} md={12} xs={12} className="">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="fs-3">Project name</Form.Label>
                <Form.Control
                  className="mt-2 p-3"
                  type="text"
                  placeholder="Arabic name"
                  style={changeBackgroundInput(darkMode)}
                  name="ar_name"
                  defaultValue={getDetailsProject.name_ar}
                  onChange={handleAddProjectChange}
                />
                <Form.Control
                  className="mt-2 p-3"
                  type="text"
                  placeholder="English name"
                  style={changeBackgroundInput(darkMode)}
                  name={"en_name"}
                  defaultValue={getDetailsProject.name_en}
                  onChange={handleAddProjectChange}
                />

                <Form.Label className="my-2 fs-3">Description</Form.Label>
                <Form.Control
                  className="mt-2 p-3"
                  type="text"
                  placeholder="Arabic description"
                  style={changeBackgroundInput(darkMode)}
                  name={"ar_description"}
                  defaultValue={getDetailsProject.ar_description}
                  onChange={handleAddProjectChange}
                />
                <Form.Control
                  className="mt-2 p-3"
                  type="text"
                  placeholder="English description"
                  style={changeBackgroundInput(darkMode)}
                  name={"en_description"}
                  defaultValue={getDetailsProject.en_description}
                  onChange={handleAddProjectChange}
                />
              </Form.Group>
            </Col>
            <Col lg={2} md={12} xs={12}>
              <div className="d-flex justify-content-center align-items-end h-100  button-create">
                <Link className="create-btn p-3" onClick={handleFileUpload}>
                  Update
                </Link>
              </div>
            </Col>
          </Form>
          <ToastContainer />
        </div>
      )}
    </Container>
  );
}
