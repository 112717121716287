import React from "react";
import { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Table from "../components/Afflentfeature/Table";
import { Link, useParams } from "react-router-dom";
import { changeBackgroundInput } from "../styleHelpers/changeBackgroundInput.js";
import Loader from "../components/Loader/Loader";

function Affilientfeature({
  darkMode,
  sub_features,
  setSubFeatures,
  handleFileUpload,
  loading,
}) {
  const { projectId } = useParams();

  const addSubFeature = () => {
    const newSubFeature = {
      id: sub_features.length + 1,
      ar_name: "",
      en_name: "",
      ar_description: "",
      en_description: "",
      ar_note: "",
      en_note: "",
      api: {
        endpoint: "",
        type: "POST",
        response: "",
        error: "",
        payloads: [
          {
            id: 1,
            name: "",
            payload_value: "",
            isRequired: true,
          },
        ],
      },
      tables: [
        {
          id: 1,
          title: "",
          description: "",
          columns_number: 1,
          columns_titles: ["title1"],
          rows: [{ id: 1, title1: "" }],
        },
      ],
    };

    setSubFeatures([...sub_features, newSubFeature]);
  };

  const handleSubFeatureChange = (e, index) => {
    const { name, value } = e.target;
    const updatedSubFeatures = [...sub_features];
    updatedSubFeatures[index][name] = value;
    setSubFeatures(updatedSubFeatures);
  };

  const handleSubFeatureChangeApi = (e, index) => {
    const n = e.target.name;
    const value = e.target.value;
    let updatedSubFeatures = [...sub_features];
    updatedSubFeatures[index].api[n] = value;
    setSubFeatures(updatedSubFeatures);
  };
  const handleSubFeatureChangeApiPayload = (e, index, i) => {
    const n = e.target.name;
    const value = e.target.value;

    let updatedSubFeatures = [...sub_features];
    updatedSubFeatures[index].api.payloads[i][n] = value;
    setSubFeatures(updatedSubFeatures);
  };

  const [payloads, setPayloads] = useState([
    {
      id: 1,
      name: "",
      payload_value: "",
      isRequired: true,
    },
  ]);

  const addNewPayload = (index) => {
    const newPayload = {
      id: sub_features[index].api.payloads.length + 1,
      name: "",
      payload_value: "",
      isRequired: false,
    };

    // تحديث الـ api الحالية بإضافة الحقل الجديد
    const updatedApi = {
      ...sub_features[index].api,
      payloads: [...sub_features[index].api.payloads, newPayload],
    };

    // تحديث الـ subFeature الحالي بإضافة الـ api الجديدة
    const updatedSubFeature = {
      ...sub_features[index],
      api: updatedApi,
    };

    // تحديث حالة ال subFeatures باستخدام ال subFeature الحالي المحدث
    const updatedSubFeatures = [...sub_features];
    updatedSubFeatures[index] = updatedSubFeature;
    setSubFeatures(updatedSubFeatures);
    setPayloads((prevPayloads) => [...prevPayloads, newPayload]);
  };

  return (
    <Container className="d-flex justify-content-between flex-wrap">
      {loading && <Loader />}
      {!loading && (
        <>
          {sub_features.map((subFeature, index) => (
            <div className="d-flex flex-wrap w-100 mb-2">
              <Row
                xs={12}
                md={12}
                className="d-flex flex-column justify-content-center align-items-center my-3"
              >
                <p className="our-project-text">Affilient feature</p>
              </Row>
              <div
                key={subFeature.id}
                className="d-flex justify-content-between flex-wrap  mb-3 form-style  py-5"
                style={
                  darkMode === true
                    ? { backgroundColor: "#042230" }
                    : { backgroundColor: "#EFEFEF" }
                }
              >
                <Col xs={11} md={5} lg={5} className="mx-3 mb-2 ">
                  <Form.Group className="mb-3 ">
                    <Form.Control
                      className="p-3"
                      type="text"
                      placeholder="Arabic feature name"
                      style={changeBackgroundInput(darkMode)}
                      name="ar_name"
                      value={subFeature.ar_name}
                      onChange={(e) => handleSubFeatureChange(e, index)}
                    />
                  </Form.Group>
                </Col>

                <Col xs={11} md={5} lg={5} className="mx-3 mb-2">
                  <Form.Group className="mb-3 ">
                    <Form.Control
                      className="p-3"
                      type="text"
                      placeholder="English feature name"
                      style={changeBackgroundInput(darkMode)}
                      name="en_name"
                      value={subFeature.en_name}
                      onChange={(e) => handleSubFeatureChange(e, index)}
                    />
                  </Form.Group>
                </Col>

                <Col xs={11} md={5} lg={5} className="mx-3">
                  <Form.Group className="mb-3">
                    <Form.Control
                      className="p-3"
                      type="text"
                      placeholder="Arabic description"
                      style={changeBackgroundInput(darkMode)}
                      name="ar_description"
                      value={subFeature.ar_description}
                      onChange={(e) => handleSubFeatureChange(e, index)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={11} md={5} lg={5} className="mx-3">
                  <Form.Group className="mb-3">
                    <Form.Control
                      className="p-3"
                      type="text"
                      placeholder="English description"
                      style={changeBackgroundInput(darkMode)}
                      name="en_description"
                      value={subFeature.en_description}
                      onChange={(e) => handleSubFeatureChange(e, index)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={11} md={5} lg={5} className="mx-3">
                  <textarea
                    style={changeBackgroundInput(darkMode)}
                    className="w-100 text-aeria"
                    placeholder="Note Arabic"
                    name="ar_note"
                    value={subFeature.ar_note}
                    onChange={(e) => handleSubFeatureChange(e, index)}
                  ></textarea>
                </Col>
                <Col xs={11} md={5} lg={5} className="mx-3">
                  <textarea
                    style={changeBackgroundInput(darkMode)}
                    className="w-100 text-aeria"
                    placeholder=" Note English"
                    name="en_note"
                    value={subFeature.en_note}
                    onChange={(e) => handleSubFeatureChange(e, index)}
                  ></textarea>
                </Col>
              </div>
              <Row
                xs={11}
                md={12}
                className="d-flex flex-column justify-content-center align-items-center my-3"
              >
                <p className="our-project-text" style={{ color: "#9B9999" }}>
                  API
                </p>
              </Row>

              <div
                className="d-flex justify-content-between flex-wrap  mb-3 form-style py-5"
                style={
                  darkMode === true
                    ? { backgroundColor: "#042230" }
                    : { backgroundColor: "#EFEFEF" }
                }
              >
                <Col xs={11} md={5} lg={5} className="mx-3 mt-3">
                  <Form.Select
                    className="w-50 "
                    style={changeBackgroundInput(darkMode)}
                    name="type"
                    value={subFeature.api.type}
                    onChange={(e) => handleSubFeatureChangeApi(e, index)}
                  >
                    <option disabled>Type</option>
                    <option>Post</option>
                    <option>Get</option>
                    <option>Put</option>
                    <option>Delete</option>
                  </Form.Select>
                </Col>
                <Col xs={11} md={5} lg={5} className="mx-3 mb-2">
                  <Form.Group className="mt-3">
                    <Form.Control
                      className="p-3"
                      type="text"
                      placeholder="Endpoint URL"
                      style={changeBackgroundInput(darkMode)}
                      name="endpoint"
                      value={subFeature.api.endpoint}
                      onChange={(e) => handleSubFeatureChangeApi(e, index)}
                    />
                  </Form.Group>
                </Col>

                <div
                  className=" m-3 p-1 app-payload"
                  style={
                    darkMode === false
                      ? { backgroundColor: "#C3C8CB" }
                      : { backgroundColor: "#052736" }
                  }
                >
                  <div className="w-100">
                    <Row xs={11} md={12} className=" my-3 mx-2 ">
                      <p
                        className="our-project-text fs-3"
                        style={{ color: "#9B9999" }}
                      >
                        Payload
                      </p>
                    </Row>
                  </div>
                  {sub_features[index].api.payloads.map((payload, i) => (
                    <div
                      className="d-flex justify-content-between flex-wrap w-100 pt-3"
                      key={payload.id}
                    >
                      <Col xs={11} md={5} lg={5} className="mx-3 ">
                        <Form.Group className="mb-3">
                          <Form.Control
                            className="p-3"
                            type="text"
                            placeholder="Payload name"
                            style={changeBackgroundInput(darkMode)}
                            name={"name"}
                            onChange={(e) =>
                              handleSubFeatureChangeApiPayload(e, index, i)
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={11} md={5} lg={5} className="mx-3">
                        <Form.Group className="mb-3">
                          <Form.Control
                            className="p-3"
                            type="text"
                            placeholder="Payload values"
                            style={changeBackgroundInput(darkMode)}
                            name={"payload_value"}
                            onChange={(e) =>
                              handleSubFeatureChangeApiPayload(e, index, i)
                            }
                          />
                          <span
                            className="d-flex justify-content-center"
                            style={{ fontSize: "inherit" }}
                          >
                            put (-) between values
                          </span>
                        </Form.Group>
                      </Col>
                      <Col xs={11} md={5} lg={5} className="mx-3">
                        <Form.Select
                          className="w-50 "
                          style={changeBackgroundInput(darkMode)}
                          name={"isRequired"}
                          onChange={(e) =>
                            handleSubFeatureChangeApiPayload(e, index, i)
                          }
                        >
                          <option value="">Select Requierd or Optinal</option>
                          <option value={"Requierd"}>Requierd</option>
                          <option value={"Optinal"}>Optinal</option>
                        </Form.Select>
                      </Col>

                      <Col xs={11} md={5} lg={5} className="mx-3">
                        <div
                          className="  app-payload "
                          style={
                            darkMode === false
                              ? { backgroundColor: "#C3C8CB" }
                              : { backgroundColor: "#052736" }
                          }
                        ></div>
                      </Col>
                    </div>
                  ))}
                  <div className="d-flex justify-content-center">
                    <button
                      type="button"
                      className="new-payload  mb-3 p-3"
                      onClick={() => addNewPayload(index)}
                    >
                      + Add new payload
                    </button>
                  </div>
                </div>

                <Col xs={11} md={5} lg={5} className="mx-3">
                  <textarea
                    style={changeBackgroundInput(darkMode)}
                    className="w-100 text-aeria"
                    placeholder="Json response"
                    name="response"
                    value={subFeature.api.response}
                    onChange={(e) => handleSubFeatureChangeApi(e, index)}
                  ></textarea>
                </Col>
                <Col xs={11} md={5} lg={5} className="mx-3">
                  <textarea
                    style={changeBackgroundInput(darkMode)}
                    className="w-100 text-aeria"
                    placeholder=" Json errors"
                    name="error"
                    value={subFeature.api.error}
                    onChange={(e) => handleSubFeatureChangeApi(e, index)}
                  ></textarea>
                </Col>
              </div>

              <Table
                darkMode={darkMode}
                sub_features={sub_features}
                setSubFeatures={setSubFeatures}
                index={index}
              />
            </div>
          ))}
          <div className="d-flex justify-content-center align-items-center w-100 ss">
            <button
              type="button"
              onClick={() => addSubFeature()}
              className="New-attributie-btn mt-3 mb-3 p-3"
            >
              New feature
            </button>
          </div>
          <div className="d-flex justify-content-between flex-wrap my-5 w-100">
            <Link
              className="New-attributie-btn mt-3 mb-3 p-3 w-100 mb-5"
              onClick={() => handleFileUpload(projectId)}
            >
              Create
            </Link>
          </div>
        </>
      )}
    </Container>
  );
}

export default Affilientfeature;
