import React from "react";
import { Row } from "react-bootstrap";
import "../Styles/Ourproject.css";
import Loader from "../components/Loader/Loader";
import Translation from "../Data/Data-Welcom.json";
import { useSelector } from "react-redux";
import ProjectsComponent from "../components/Ourproject/ProjectsComponentClient";
import NoProjectComponent from "../components/OurProjects/NoProjectComponent";

export default function Ourproject({ contents, language }) {
  const allProjects = useSelector((state) => state.projects.data);
  const loading = useSelector((state) => state.projects.loading);

  return (
    <div>
      {loading && <Loader />}
      {!loading && (
        <div>
          <Row xs={12} md={12} className=" mx-5 my-2">
            <p className="our-project-text">
              {Translation[contents]["ourproject"]}
            </p>
          </Row>
          <div className=" d-flex justify-content-between flex-wrap mx-1">
            {allProjects.length >= 1 ? (
              allProjects.map((projects) => {
                return (
                  <ProjectsComponent projects={projects} language={language} />
                );
              })
            ) : (
              <NoProjectComponent />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
