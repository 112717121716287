import React, { useState } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-tomorrow_night_bright";
import "./codemirorr.css";

const jsonData = {
  state: true,
  code: "200",
  data: {
    id: 1,
    agreement_number: 1001,
    status: "waiting",
    preface: "describe the argument",
    url: "",
    contracting_parties: [
      {
        id: 1,
        contractor_type_id: 1,
        contractor_type: "office",
        office_id: 1,
        office_name: "office",
        phone: "+963 987654321",
      },
      {
        id: 2,
        contractor_type_id: 2,
        contractor_type: "person",
        client_name: "name",
        document_type_id: 1,
        document_type: "identity",
        document_number: "0401023231",
        nationality: " Syrian",
        phone: {
          number: "0998765432",
          country_code: "",
          country_iso_code: "",
        },
      },
    ],
    provisions: [
      {
        id: 1,
        title: "title 1",
        body: "body 1",
      },
      {
        id: 2,
        title: "title 2",
        body: "body 2",
      },
    ],
  },
};

const CodeEditor = () => {
  const [code, setCode] = useState(JSON.stringify(jsonData, null, 2));

  const [isCopying, setIsCopying] = useState(false);
  const handleCopyClick = () => {
    setIsCopying(true);
    setTimeout(() => {
      setIsCopying(false);
    }, 5000);
    navigator.clipboard.writeText(code);
  };

  return (
    <div className="editor">
      <AceEditor
        mode="json"
        theme="tomorrow_night_bright"
        name="code-editor"
        value={JSON.stringify(jsonData, null, 1)}
        editorProps={{ $blockScrolling: false }}
        tabSize={20}
        readOnly={true}
        fontSize={17}
        width={"100%"}
      />
      <button className="Copy" onClick={handleCopyClick}>
        {isCopying ? "Coping" : "Copy"} <i className="fa-solid fa-copy"></i>
      </button>
    </div>
  );
};

export default CodeEditor;
