import React from "react";
import { Row } from "react-bootstrap";
import "../Styles/Ourproject.css";
import { Link } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import Translation from "../Data/Data-Welcom.json";
import ModalComponent from "../components/OurProjects/ModalComponent";
import { useSelector } from "react-redux";
import NoProjectComponent from "../components/OurProjects/NoProjectComponent";
import ProjectsComponent from "../components/OurProjects/ProjectsComponent";

export default function Ourprojects({
  contents,
  showChildComponent,
  setShowChildComponent,
  selectedProjectId,
  setSelectedProjectId,

  language,
}) {
  const projects = useSelector((state) => state.projects.data);

  const loading = useSelector((state) => state.projects.loading);

  return (
    <div>
      {loading && <Loader />}
      {!loading && (
        <div>
          <Row xs={12} md={12} className=" mx-5 my-2">
            <p className="our-project-text">
              {Translation[contents]["ourproject"]}
            </p>
          </Row>
          <div className=" d-flex justify-content-between flex-wrap mx-1">
            {projects.length >= 1 ? (
              projects.map((projects) => {
                return (
                  <ProjectsComponent
                    projects={projects}
                    language={language}
                    setSelectedProjectId={setSelectedProjectId}
                    showChildComponent={showChildComponent}
                    setShowChildComponent={setShowChildComponent}
                  />
                );
              })
            ) : (
              <NoProjectComponent />
            )}

            {showChildComponent && (
              <ModalComponent
                showChildComponent={showChildComponent}
                setShowChildComponent={setShowChildComponent}
                selectedProjectId={selectedProjectId}
              />
            )}
            {projects.length >= 1 ? (
              <div className="py-4 App-cards mx-5">
                <Link
                  to={"/Ourprojects/Addproject"}
                  className=" add-new-project"
                >
                  <i className="fa-solid fa-plus mx-2 my-1"></i>
                  <p className=" mx-2 my-1">Add new project</p>
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
}
