// ProjectsComponent.js
import React, { useState } from "react";
import { Card } from "react-bootstrap";

export default function ProjectsComponent({
  projects,
  language,
  setSelectedProjectId,
  showChildComponent,
  setShowChildComponent,
}) {
  const [load, setload] = useState(true);

  const handleImageLoad = () => {
    setload(false);
  };
  return (
    <div className="App-cards mx-5" key={projects.id}>
      {projects.id}

      <div className="d-flex justify-content-between flex-wrap App-card">
        <div className="card">
          <div className="link-card">
            {load && <div className="loader">Loading...</div>}
            <img
              src={projects.image_url}
              alt="sdd"
              className="img-project "
              onLoad={handleImageLoad}
              style={{ minWidth: "100%", height: "200px" }}
              onClick={() =>
                (window.location.pathname = `/Ourprojects/Invesment/${projects.id}`)
              }
            ></img>
          </div>

          <Card.Title className="title-projext">
            {language ? projects.name_en : projects.name_ar}
          </Card.Title>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22.303"
            height="20.532"
            viewBox="0 0 22.303 20.532"
            className="svg-img"
          >
            <path
              id="Path_1"
              data-name="Path 1"
              d="M9137,1711.876V1693l10.023,9.282,10.478,9.122Z"
              transform="translate(-9136.5 -1691.855)"
              fill="#003b57"
              stroke="#003b57"
              strokeWidth="1"
              opacity="0.89"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
