import React from "react";
import "./slider.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

export default function Slider({ data }) {
  const params = {
    spaceBetween: 30,
    breakpoints: {
      1024: {
        spaceBetween: 40,
      },
      768: {
        spaceBetween: 30,
      },
      640: {
        spaceBetween: 20,
      },
      320: {
        spaceBetween: 10,
      },
    },
  };
  return (
    <div className="d-flex flex-column py-5">
      <Swiper
        {...params}
        loop={true}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        effect={"coverflow"}
        centeredSlides={true}
        slidesPerView={3}
        className="swiper"
        modules={[Autoplay, Pagination]}
        pagination={{ el: ".swiper-pagimation", clickable: true }}
      >
        {data.images &&
          data.images.map((img, index) => {
            return (
              <SwiperSlide key={img}>
                <img src={img} alt="ss" />
              </SwiperSlide>
            );
          })}
      </Swiper>
      <div className="slider-controler py-5">
        <div className="swiper-pagimation"></div>
      </div>
    </div>
  );
}
