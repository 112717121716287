import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";

export default function InputImages({ data, setImages }) {
  const [selectimg, setSelectimg] = useState([]);
  const onSelectFile = (event) => {
    const selectFiles = event.target.files;
    const selectedFilesArray = Array.from(selectFiles);
    const imagesArray = selectedFilesArray.map((file, index) => {
      const imageUrl = URL.createObjectURL(file);
      return imageUrl;
    });

    setSelectimg(imagesArray);
    setImages(selectFiles);
  };
  useEffect(() => {
    setSelectimg(data.images);
  }, [data]);
  return (
    <Row>
      <div className="d-flex justify-content-center my-5">
        <label className="label_img">
          <input
            type="file"
            name="images"
            onChange={onSelectFile}
            multiple
            accept="image/*"
            className="input_img"
          ></input>
          <div>
            {selectimg &&
              selectimg.map((img, index) => {
                return (
                  <div key={img} className="py-3 ">
                    <img src={img} alt="ss" className="App-img" />
                  </div>
                );
              })}
          </div>
        </label>
      </div>
    </Row>
  );
}
