import React, { useEffect } from "react";
import Buttonbars from "../components/Sidebar/Buttonbars";
import Sidebars from "../components/Sidebar/Sidebar";
import { Col } from "react-bootstrap";
import { Outlet, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchFeatures } from "../stores/appSlice";
export default function ProjectOverViwe({
  darkMode,
  language,
  contents,
  setActiveData,
}) {
  const { projectId } = useParams();

  const dispatch = useDispatch();
  const features = useSelector((state) => state.app.features);

  useEffect(() => {
    dispatch(fetchFeatures(projectId));
  }, [dispatch]);

  return (
    <div className="d-flex justify-content-center">
      <div className="btnsidebar-component">
        <Buttonbars
          darkMode={darkMode}
          language={language}
          contents={contents}
          data={features}
        />
      </div>

      <Col xs={0} md={0} lg={3}>
        <div className="father-hidden">
          <div className="sidebar-component">
            <Sidebars
              darkMode={darkMode}
              language={language}
              contents={contents}
              data={features}
              setActiveData={setActiveData}
            />
          </div>
        </div>
      </Col>
      <Outlet />
    </div>
  );
}
